import { DateTime } from 'luxon'

export default {
  methods: {
    // TODO: usar en el resto de componentes
    formatTime(timestamp, timezone) {
      if (!timestamp) return ''
      const time = DateTime.fromSeconds(timestamp.seconds, {
        zone: timezone,
      }).toLocaleString(DateTime.TIME_24_SIMPLE)

      return time.split(':')[0].length === 1 ? `0${time}` : time
    },
    formatDate(timestamp, timezone) {
      if (!timestamp) return ''
      return DateTime.fromSeconds(timestamp.seconds, { zone: timezone }).toLocaleString()
    },
    formatDatetime(timestamp, timezone) {
      if (!timestamp) return ''
      return `${this.formatDate(timestamp, timezone)} · ${this.formatTime(timestamp, timezone)}`
    },
    formatBirthdate(birthdate) {
      if (!birthdate) return ''
      const { day, month, year } = birthdate
      return `${day}/${month}/${year}`
    },
  },
}
