<template>
  <v-row class="my-0">
    <v-col cols="12" class="pa-3">
      <h2 class="mb-4 mt-8" v-text="$t('activity.editActivity.generalInformation')" />
      <v-row class="mb-4">
        <v-col cols="12" md="2">
          <v-text-field
            v-model="guild.assignedTicketCount" :label="$t('cluster.tickets.editMatchActivity.ticketsEnabledToMyGuild')"
            outlined dense disabled
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="guild.requested" :label="$t('cluster.tickets.editMatchActivity.ticketsRequested')"
            outlined dense disabled
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="guild.available" :label="$t('cluster.tickets.matchDetail.ticketsAvailable')"
            outlined dense disabled
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="guild.accepted" :label="$t('cluster.tickets.assignTickets.ticketsAccepted')"
            outlined dense disabled
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-text-field
            v-model="guild.purchased" :label="$t('cluster.tickets.assignTickets.ticketsPurchased')"
            outlined dense disabled
          />
        </v-col>
      </v-row>
      <div>
        <div class="d-flex justify-space-between">
          <div>
            <h2 class="mb-4" v-text="$t('cluster.tickets.editMatchActivity.guildsRequests')" />
          </div>
          <div class="d-flex justify-end">
            <v-text-field
              v-model="searchText"
              class="flex-grow-0 ml-3" :label="$t('common.search')" prepend-inner-icon="search" outlined dense
            />
          </div>
        </div>
        <v-data-table
          v-if="ticketEnrollments.length"
          :headers="headers" :items="ticketEnrollments" :search="searchText" dense
          class="mt-6 table--has-pointer"
        >
          <template #[`item.status`]="{ item }">
            <v-chip
              v-if="item.status === 'pendingApproval'"
              outlined label color="pending" style="min-width: 5rem;"
            >
              {{ $t('cluster.tickets.editMatchActivity.pendingApproval') }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'pendingPayment'"
              outlined label color="pending" style="min-width: 5rem;"
            >
              {{ $t('cluster.tickets.editMatchActivity.pendingPayment') }}
            </v-chip>
            <v-chip
              v-else-if="item.status === 'complete'"
              outlined label color="success" style="min-width: 5rem;"
            >
              {{ $t('cluster.tickets.editMatchActivity.purchased') }}
            </v-chip>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex align-center">
              <a class="mr-4"
                 @click="$router.push({ name: 'project-player', params: { playerId: item.userId } })"
                 v-text="$t('cluster.tickets.editMatchActivity.seeDetail')"
              />
              <v-btn
                v-if="item.status === 'pendingApproval'"
                icon color="success" class="justify-start" :disabled="!isAvailableButtonAccept" @click="accept(item)"
              >
                <v-icon v-text="'check_circle_outline'" />
              </v-btn>
              <v-btn v-if="item.status === 'pendingPayment'" icon color="error" class="justify-start" @click="reject(item)">
                <v-icon v-text="'highlight_off'" />
              </v-btn>
            </div>
          </template>
        </v-data-table>
        <empty-list v-else :content="$t('organization.sections.tickets')" />
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex'
import dateFilter from '@/utils/mixins/dateFilter'
import i18n from '@/plugins/i18n'

export default {
  name: 'EditTicketsGuildMatch',
  components: {
    EmptyList: () => import('@/components/EmptyList'),
  },
  mixins: [dateFilter],
  props: {
    organizationId: { type: String, required: true },
    projectId: { type: String, required: true },
    guildMatchActivityId: { type: String, required: true },
  },
  data: () => ({ guilds: [], searchText: null, ticketEnrollments: [] }),
  computed: {
    ...mapGetters('organization', ['projects']),
    ...mapGetters('project', ['activities']),
    ...mapGetters({ rawTicketEnrollments: 'guildMatches/ticketEnrollments' }),
    guild({ activities, guildMatchActivityId }) {
      const activity = activities.find(a => a.id === guildMatchActivityId)
      const assignedTicketCount = activity?.ticketCount ?? 0
      const ticketEnrollmentCountByStatus = activity?.ticketEnrollmentCountByStatus
      const requested = ticketEnrollmentCountByStatus?.pendingApproval
        ? (ticketEnrollmentCountByStatus?.pendingApproval + ticketEnrollmentCountByStatus?.pendingPayment + ticketEnrollmentCountByStatus?.complete) : 0
      const accepted = ticketEnrollmentCountByStatus?.pendingPayment
        ? (ticketEnrollmentCountByStatus?.pendingPayment + ticketEnrollmentCountByStatus?.complete) : 0
      const available = assignedTicketCount - accepted
      const purchased = ticketEnrollmentCountByStatus?.complete ?? 0
      return { activity, assignedTicketCount, requested, available, accepted, purchased }
    },
    headers: () => [
      { text: i18n.t('common.name'), value: 'name', cellClass: 'py-4' },
      { text: i18n.t('cluster.tickets.editMatchActivity.numberPlayerGuild'), value: 'numberPlayerGuild', cellClass: 'py-4' },
      { text: i18n.t('user.fields.email'), value: 'email', cellClass: 'py-4' },
      { text: i18n.t('project.enrollments.requestDate'), value: 'requestDate', cellClass: 'py-4' },
      { text: i18n.t('common.status'), value: 'status', cellClass: 'py-4' },
      { text: i18n.t('common.actions'), value: 'actions', cellClass: 'py-4' },
    ],
    isAvailableButtonAccept({ guild, ticketEnrollments }) {
      const totalPendingPayment = ticketEnrollments.filter(t => t.status === 'pendingPayment').length
      const totalComplete = ticketEnrollments.filter(t => t.status === 'complete').length
      return totalPendingPayment + totalComplete < guild.assignedTicketCount
    },
  },
  watch: {
    rawTicketEnrollments: {
      immediate: true,
      async handler(ticketEnrollments) {
        this.$store.commit('loader/show')
        this.ticketEnrollments = await Promise.all(ticketEnrollments.map(async ticketEnroll => {
          const userMembership = await this.$store.dispatch(
            'user/readMembershipInfo',
            { id: ticketEnroll.userId, role: 'subscriber', entityType: 'project', entityId: this.projectId },
          )
          const userData = await this.$store.dispatch('user/read', ticketEnroll.userId)
          return {
            ...ticketEnroll,
            name: `${userData?.firstName} ${userData?.lastName}`.trim(),
            numberPlayerGuild: userMembership.form?.code,
            email: userData.email ?? '-',
            requestDate: this.formatDate(userMembership.enrollmentCreatedAt),
          }
        }))
        this.$store.commit('loader/hide')
      },
    },
  },
  async created() {
    this.$store.commit('loader/show')
    const { organizationId, projectId } = this
    await this.$store.dispatch('guildMatches/bindTicketEnrollments', { organizationId, projectId, activityId: this.guildMatchActivityId })
    this.$store.commit('loader/hide')
  },
  methods: {
    async accept(item) {
      const { organizationId, projectId, guild: { activity } } = this
      const { id, purchaseId, userId, email } = item
      this.runAsync(async () => {
        await this.$store.dispatch('guildMatches/approveTicketEnrollment', {
          organizationId, projectId, id, purchaseId, data: { status: 'pendingPayment' }, email, activity, userId,
        })
      })
    },

    async reject(item) {
      const { organizationId, projectId, guild: { activity } } = this
      const { id, purchaseId, userId, email } = item
      this.runAsync(async () => {
        await this.$store.dispatch('guildMatches/rejectTicketEnrollment', {
          organizationId, projectId, id, purchaseId, data: { status: 'pendingApproval' }, email, activity, userId,
        })
      })
    },
  },
}
</script>
